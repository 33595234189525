<template>
	<nav class="sidenav">
		<div class="sidenav__logo-container">
			<img class="sidenav__logo" src="@/assets/images/logo_duxiter.svg" alt="Logo Duxiter" />
		</div>
		<div class="sidenav__persona-info persona-info">
			<div class="persona-info__image-container">
				<!-- <img
          class="persona-info__image"
          src="@/assets/images/example_profile-pic.png"
          alt="Imagen persona"
        /> -->
				<div class="persona-info__initials">
					<h3>
						{{ getInitials($store.state?.user?.name) }}
					</h3>
				</div>
			</div>
			<div class="persona-info__data">
				<h4 class="persona-info__name">{{ $store.state?.user?.name }}<br /></h4>
			</div>
		</div>

		<SideNavComponentAdmin :routeActive="routeActive" v-if="$store.state.user?.role_id === 1" />
		<SideNavComponentProvider :routeActive="routeActive" v-if="$store.state.user?.role_id === 2" />
		<SideNavComponentBuyer :routeActive="routeActive" v-if="$store.state.user?.role_id === 3" />

		<div class="sidenav__items sidenav__items--bottom">
			<a class="sidenav__item sidenav__item--no-bg" href="#" @click.prevent="confirmLogout">
				<img
					class="sidenav__ico"
					src="@/assets/images/icons/ico_logout--black.svg"
					alt="Icono Dashboard"
				/>
				<span class="sidenav__text">Cerrar sesión</span>
			</a>
		</div>
	</nav>
</template>

<script>
import SideNavComponentAdmin from '@/components/SideNavComponentAdmin.vue';
import SideNavComponentProvider from '@/components/SideNavComponentProvider.vue';
import SideNavComponentBuyer from '@/components/SideNavComponentBuyer.vue';
export default {
	name: 'SidenavComponent',
	components: {
		SideNavComponentAdmin,
		SideNavComponentProvider,
		SideNavComponentBuyer
	},
	data() {
		return {
			user: {
				company: 'Empresa representada'
			},
			company: {
				id: 1
			},
			routeActive: null,
			userRole: 'provider'
		};
	},
	watch: {
		$route(to) {
			this.routeActive = to.meta.section;
		}
	},
	methods: {
		confirmLogout() {
			sessionStorage.clear();
			var vuexStorage = localStorage.getItem('vuex');
			this.$cookies.remove('jwt');
			localStorage.clear();
			localStorage.setItem('vuex', vuexStorage);
			this.$router.push({ path: '/', query: { logout: true } });
		},
		getInitials(name) {
			if (!name) return '';
			const words = name.split(' ');
			const initials = words.map((word) => word.charAt(0).toUpperCase()).join('');
			return initials;
		}
	}
};
</script>

<style scoped>
.persona-info__name {
	max-width: 150px;
	word-wrap: break-word;
	overflow-wrap: break-word;
}
</style>
