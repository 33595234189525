<template>
	<div class="sidenav__items">
		<!-- Mi Panel -->
		<router-link
			:class="['sidenav__item', routeActive == 'Dashboard' ? 'sidenav__item--active' : '']"
			:to="{ name: 'Dashboard' }"
		>
			<img
				v-if="routeActive == 'Dashboard'"
				class="sidenav__ico"
				src="@/assets/images/icons/ico_dashboard--white.svg"
				alt="Icono Dashboard"
			/>
			<img
				v-else
				class="sidenav__ico"
				src="@/assets/images/icons/ico_dashboard--black.svg"
				alt="Icono Dashboard"
			/>
			<span class="sidenav__text">Mi Panel</span>
		</router-link>

		<!-- Prospectos -->
		<router-link
			:class="['sidenav__item', routeActive == 'Applicants' ? 'sidenav__item--active' : '']"
			:to="{ name: 'Applicants' }"
		>
			<img
				v-if="routeActive == 'Applicants'"
				class="sidenav__ico"
				src="@/assets/images/icons/ico_clients--white.svg"
				alt="Icono Dashboard"
			/>
			<img
				v-else
				class="sidenav__ico"
				src="@/assets/images/icons/ico_clients--black.svg"
				alt="Icono Dashboard"
			/>
			<span class="sidenav__text">Prospectos</span>
		</router-link>

		<!-- Clients -->
		<router-link
			:class="['sidenav__item', routeActive == 'Clients' ? 'sidenav__item--active' : '']"
			:to="{ name: 'Buyers' }"
		>
			<img
				v-if="routeActive == 'Clients'"
				class="sidenav__ico"
				src="@/assets/images/icons/ico_clients--white.svg"
				alt="Icono Dashboard"
			/>
			<img
				v-else
				class="sidenav__ico"
				src="@/assets/images/icons/ico_clients--black.svg"
				alt="Icono Dashboard"
			/>
			<span class="sidenav__text">Clientes</span>
		</router-link>

		<!-- Ajustes del sistema -->
		<router-link
			:class="['sidenav__item', routeActive == 'Settings' ? 'sidenav__item--active' : '']"
			:to="{ name: 'GroupsAndPersons' }"
		>
			<img
				v-if="routeActive == 'Settings'"
				class="sidenav__ico"
				src="@/assets/images/icons/ico_settings--white.svg"
				alt="Icono Dashboard"
			/>
			<img
				v-else
				class="sidenav__ico"
				src="@/assets/images/icons/ico_settings--black.svg"
				alt="Icono Dashboard"
			/>
			<span class="sidenav__text">Ajustes del sistema</span>
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'AdminSideNavComponent',
	props: {
		routeActive: {
			type: String
		}
	}
};
</script>
