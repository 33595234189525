import createPersistedState from "vuex-persistedstate";
import { createStore } from 'vuex';

export default createStore({
  state: {
    user: null,
    accessedViaButton: false
  },
  mutations: {
    setUserData (state, value) {
      if (value == null) {
        state.user = null;
      } else {
        const full_name = `${value.name} ${value.lastname}`;
        state.user = {
          ...value,
          full_name: full_name
        };
      }
    },
    setAccessedViaButton(state, status) {
      state.accessedViaButton = status;
    }
  },
  actions: {},
  getters: {},
  modules: {},
  plugins: [createPersistedState()]
});